<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Survey</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<div
								class="input-group input-group-flush d-flex flex-row-reverse"
								>
								<input
									v-model.trim="search"
									class="form-control list-search"
									type="search"
									placeholder="Search"
									/>
								<span class="input-group-text border-0">
									<i class="fe fe-search"></i>
								</span>
							</div>
						</div>
						<b-table
							striped
							hover
							responsive
							:items="filteredSurvey"
							:fields="fields"
							:per-page="perPage"
							:current-page="currentPage"
							:busy="fetchingSurveys"
							>
							<template #table-busy>
								<div class="text-center text-secondary my-2">
									<strong>Loading...</strong>
								</div>
							</template>
							<template #cell(user)="data">
								<p class="font-weight-bold" style="line-height: 1">
									{{ `${data.item.fname} ${data.item.lname}` }}
								</p>
								<p style="line-height: 1">{{ data.item.email }}</p>
								<p style="line-height: 1">{{ data.item.phone }}</p>
							</template>
							<template #cell(survey_type)="data">
								<span>{{ data.value.split('_').join(' ') | titleCase }}</span>
							</template>
							<template #cell(is_driver)="data">
								<span>{{ data.item.carDetail ? 'Yes' : 'No' }}</span>
							</template>
						</b-table>

						<div class="card-footer" v-if="filteredSurvey.length">
							<b-pagination
								v-model="currentPage"
								:total-rows="filteredSurvey.length"
								:per-page="perPage"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      fields: [
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'gender',
          label: 'Gender'
        },
        {
          key: 'industry',
          label: 'Industry'
        },
        {
          key: 'survey_type',
          label: 'Survey Category'
        },
        {
          key: 'is_driver',
          label: 'Is Driver'
        },
        {
          key: 'subscriptionOption',
          label: 'Subscription Option'
        },
        {
          key: 'pickUp',
          label: 'Pick Up'
        },
        {
          key: 'dropOff',
          label: 'Drop Off'
        }
      ],
      perPage: 20,
      currentPage: 1,
      surveys: [],
      fetchingSurveys: true,
      search: ''
    }
  },
  created () {
    this.fetchSurvey()
  },
  computed: {
    filteredSurvey () {
      const search = this.search.toLowerCase()

      return this.surveys.filter((survey) => {
        return (
          survey.fname.toLowerCase().includes(search) ||
          survey.lname.toLowerCase().includes(search) ||
          survey.email.toLowerCase().includes(search) ||
          survey.phone.toLowerCase().includes(search)
        )
      })
    }
  },
  methods: {
    fetchSurvey () {
      this.fetchingSurveys = true

      this.axios
        .get('/v1/survey')
        .then((res) => {
          this.surveys = res.data.map((survey) => ({
            ...JSON.parse(survey.payload),
            survey_type: survey.survey_type
          }))
        })
        .finally(() => (this.fetchingSurveys = false))
    }
  }
}
</script>

<style lang="css" scoped>
.VueTables__search {
  padding-left: 10px !important;
}
</style>
